import React from 'react';
import SearchComponent from './SearchComponent';
import ChatbotButton from './ChatbotButton'; // Importa el ChatbotButton

function App() {
  return (
    <div className="App">
      <SearchComponent />
      <ChatbotButton /> {/* Renderiza el ChatbotButton aquí */}
    </div>
  );
}

export default App;
