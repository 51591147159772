import React, { useState, useEffect, useRef } from "react";
import { supabase } from "./supabaseClient";
import {
  FaSpinner,
  FaPaperclip,
  FaTimes,
  FaHeart,
  FaShare,
  FaSearch,
  FaCalendarAlt,
  FaRoad,
  FaFilter,
  FaGasPump,
  FaCarSide,
  FaDollarSign,
  FaBolt,
  FaTag,
  // Eliminamos FaWhatsapp de las importaciones
  FaCar,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

// Importamos el ChatbotButton
import ChatbotButton from "./ChatbotButton";

const SearchComponent = () => {
  const [vehicles, setVehicles] = useState([]);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showDot, setShowDot] = useState(true);
  const [showText, setShowText] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [likedCars, setLikedCars] = useState([]);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [isAccelerating, setIsAccelerating] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [showExteriorImage, setShowExteriorImage] = useState(true);

  // Estados para la paginación
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const pageSize = 20; // Cambiamos a 20 vehículos por página para mostrar 5 filas

  const [advancedFilters, setAdvancedFilters] = useState({
    priceRange: "0-50000",
    minYear: "2004",
    maxYear: "2024",
    fuelType: "",
    brand: "",
    model: "",
  });

  const priceRanges = [
    { label: "€0 - €50,000", value: "0-50000" },
    { label: "€50,000 - €100,000", value: "50000-100000" },
    { label: "€100,000 - €250,000", value: "100000-250000" },
    { label: "€250,000 - €500,000", value: "250000-500000" },
    { label: "€500,000 - €1,000,000", value: "500000-1000000" },
    { label: "€1,000,000+", value: "1000000-" },
  ];

  const carBrands = [
    "Ferrari",
    "Lamborghini",
    "Porsche",
    "BMW",
    "Mercedes-Benz",
    "Audi",
    "Tesla",
    "McLaren",
  ];
  const carModels = {
    Ferrari: ["California", "F8 Tributo", "812 Superfast", "SF90 Stradale"],
    Lamborghini: ["Aventador", "Huracán", "Urus", "Countach"],
    Porsche: ["911", "Cayenne", "Panamera", "Taycan"],
    BMW: ["M3", "M4", "M5", "M8", "i8"],
    "Mercedes-Benz": ["AMG GT", "S-Class", "G-Class", "EQS"],
    Audi: ["R8", "RS e-tron GT", "RS6", "RS Q8"],
    Tesla: ["Model S", "Model 3", "Model X", "Model Y"],
    McLaren: ["720S", "765LT", "Artura", "GT"],
  };

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchVehicles();

    const channel = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "VEHÍCULOS" },
        (payload) => {
          fetchVehicles();
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [currentPage]); // Añadimos currentPage para actualizar al cambiar de página

  const fetchVehicles = async () => {
    setIsLoading(true);

    // Obtener el total de vehículos para calcular el número total de páginas
    const { count: totalVehicles } = await supabase
      .from("VEHÍCULOS")
      .select("*", { count: "exact", head: true });

    // Calcular el rango para la paginación
    const from = (currentPage - 1) * pageSize;
    const to = from + pageSize - 1;

    // Obtener los vehículos para la página actual
    const { data, error } = await supabase
      .from("VEHÍCULOS")
      .select("*")
      .order("id", { ascending: true })
      .range(from, to);

    if (error) {
      console.error("Error al cargar los vehículos:", error);
    } else {
      setVehicles(data || []);
      setTotalPages(Math.ceil(totalVehicles / pageSize));
    }
    setIsLoading(false);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newUploadedFiles = files.map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      type: file.type,
      size: file.size,
      preview: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
    }));
    setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
  };

  const removeFile = (fileId) => {
    setUploadedFiles((prev) => prev.filter((file) => file.id !== fileId));
  };

  const FilePreview = () => (
    <AnimatePresence>
      {uploadedFiles.length > 0 && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 p-4 bg-gray-800/50 rounded-xl backdrop-blur-xl border border-gray-700/50"
        >
          <div className="flex flex-wrap gap-4">
            {uploadedFiles.map((file) => (
              <div key={file.id} className="relative group">
                {file.preview ? (
                  <div className="relative w-24 h-24 rounded-lg overflow-hidden">
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="w-full h-full object-cover"
                    />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                ) : (
                  <div className="w-24 h-24 rounded-lg bg-gray-700 flex items-center justify-center relative">
                    <FaCar className="text-2xl text-gray-400" />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                )}
                <p className="mt-1 text-xs text-gray-400 truncate max-w-[96px]">
                  {file.name}
                </p>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const handleLike = (carIndex) => {
    setLikedCars((prev) =>
      prev.includes(carIndex)
        ? prev.filter((index) => index !== carIndex)
        : [...prev, carIndex]
    );
  };

  const handleShare = (car) => {
    const url = window.location.href + `?car=${encodeURIComponent(car.model)}`;
    setShareUrl(url);
    setShowShareModal(true);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert("¡Enlace copiado al portapapeles!");
      setShowShareModal(false);
    } catch (err) {
      alert("Error al copiar el enlace");
    }
  };

  const handleViewDetails = (car) => {
    setSelectedCar(car);
    setShowExteriorImage(true);
  };

  // Redirigir al enlace almacenado en Supabase
  const handleSolicitarCotizacion = (car) => {
    if (car && car.link) {
      window.open(car.link, "_blank");
    } else {
      alert("Enlace no disponible para este vehículo.");
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);

    // Reiniciar a la página 1 al realizar una búsqueda
    setCurrentPage(1);

    // Obtener el total de vehículos que coinciden con la búsqueda
    const { count: totalVehicles } = await supabase
      .from("VEHÍCULOS")
      .select("*", { count: "exact", head: true })
      .ilike("model", `%${query}%`);

    // Calcular el rango para la paginación
    const from = (currentPage - 1) * pageSize;
    const to = from + pageSize - 1;

    const { data, error } = await supabase
      .from("VEHÍCULOS")
      .select("*")
      .ilike("model", `%${query}%`)
      .order("id", { ascending: true })
      .range(from, to);

    if (error) {
      console.error("Error al buscar vehículos:", error);
    } else {
      setVehicles(data || []);
      setTotalPages(Math.ceil(totalVehicles / pageSize));
    }
    setIsLoading(false);
  };

  const handleFilter = (type) => {
    setIsAccelerating(true);
    // Implementar filtrado según el tipo
    // ...

    setTimeout(() => {
      setIsAccelerating(false);
    }, 2000);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPagination = () => (
    <div className="flex items-center justify-center mt-8 space-x-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-3 py-1 rounded-lg ${
          currentPage === 1
            ? "bg-gray-600 cursor-not-allowed"
            : "bg-gray-800 hover:bg-gray-700"
        } text-white`}
      >
        Anterior
      </button>

      {/* Botones de páginas */}
      {Array.from({ length: totalPages }, (_, index) => index + 1).map(
        (page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 rounded-lg ${
              currentPage === page
                ? "bg-red-600 text-white"
                : "bg-gray-800 text-white hover:bg-gray-700"
            }`}
          >
            {page}
          </button>
        )
      )}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 rounded-lg ${
          currentPage === totalPages
            ? "bg-gray-600 cursor-not-allowed"
            : "bg-gray-800 hover:bg-gray-700"
        } text-white`}
      >
        Siguiente
      </button>
    </div>
  );

  const renderAdvancedSearch = () => (
    <AnimatePresence>
      {showAdvancedSearch && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 bg-gray-800/50 rounded-xl p-4 backdrop-blur-xl border border-gray-700/50 overflow-hidden"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Campos de búsqueda avanzada */}
            {/* ... */}
          </div>
          <button
            onClick={handleSearch}
            className="mt-4 w-full bg-gradient-to-r from-red-600 to-red-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-red-500/30 transition-all"
          >
            Aplicar Filtros
          </button>
          <motion.div
            className="flex justify-center mt-4 overflow-hidden"
            animate={isAccelerating ? { x: ["-100%", "100%"] } : {}}
            transition={{ duration: 2, ease: "linear", repeat: Infinity }}
          >
            <FaCarSide
              className={`text-4xl ${
                isAccelerating ? "text-red-500" : "text-yellow-500"
              }`}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const renderCarDetailModal = () => (
    <AnimatePresence>
      {selectedCar && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          onClick={() => setSelectedCar(null)}
        >
          <motion.div
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95 }}
            className="bg-gray-900 rounded-2xl p-6 max-w-md w-full text-white shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Contenido del modal */}
            {/* ... */}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <div className="min-h-screen bg-black p-4 sm:p-8 flex flex-col items-center justify-center relative">
      <motion.h1
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="text-4xl font-bold text-center text-white mb-6"
      >
        ACCAR STOCK
      </motion.h1>
      <div className="w-full max-w-5xl mb-8">
        <div className="relative backdrop-blur-xl bg-gray-800/30 rounded-3xl shadow-2xl transition-all duration-300 overflow-hidden border border-gray-700/50">
          <div className="p-4 sm:p-6">
            <div className="relative flex flex-col sm:flex-row items-center gap-4">
              <div className="hidden sm:block">
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*,video/*,.pdf,.doc,.docx"
                  onChange={handleFileUpload}
                  multiple
                />
                <button
                  onClick={() => fileInputRef.current.click()}
                  className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                  aria-label="Adjuntar archivo"
                >
                  <FaPaperclip className="text-red-400 text-xl group-hover:scale-110 transition-transform" />
                </button>
              </div>
              <div className="relative flex-1 w-full">
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                  className="w-full px-4 sm:px-6 py-3 sm:py-4 text-gray-200 bg-gray-700/50 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 transition-all placeholder-gray-400 backdrop-blur-xl"
                  placeholder="Buscar vehículos de lujo"
                  aria-label="Campo de búsqueda"
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={handleSearch}
                >
                  {isLoading ? (
                    <FaSpinner className="animate-spin text-red-500 text-xl" />
                  ) : (
                    <FaSearch className="text-gray-400 text-xl" />
                  )}
                </div>
              </div>
              <button
                onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                aria-label="Búsqueda avanzada"
              >
                <FaFilter
                  className={`text-red-400 text-xl group-hover:scale-110 transition-transform ${
                    showAdvancedSearch ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>
            <FilePreview />
            {renderAdvancedSearch()}
          </div>
        </div>

        {/* Botones de filtros */}
        <div className="mt-6 flex flex-wrap justify-center gap-3">
          <button
            onClick={() => handleFilter("expensive")}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700 transition-all shadow-lg"
          >
            <FaDollarSign className="text-yellow-500" />
            <span>Coches Más Caros</span>
          </button>
          <button
            onClick={() => handleFilter("electric")}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700 transition-all shadow-lg"
          >
            <FaBolt className="text-red-500" />
            <span>Coches Eléctricos</span>
          </button>
          <button
            onClick={() => handleFilter("cheap")}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700 transition-all shadow-lg"
          >
            <FaTag className="text-purple-500" />
            <span>Coches Más Baratos</span>
          </button>
        </div>
      </div>

      <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {vehicles.map((car, index) => (
          <motion.div
            key={car.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-800/50 rounded-2xl overflow-hidden border border-gray-700/50 backdrop-blur-xl hover:shadow-xl hover:shadow-red-500/10 transition-all duration-300 h-full"
          >
            <div className="relative group h-48">
              <img
                src={car.image}
                alt={car.model}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute top-4 right-4 space-x-2">
                <button
                  onClick={() => handleLike(index)}
                  className={`p-2 ${
                    likedCars.includes(index) ? "bg-red-500" : "bg-gray-900/80"
                  } rounded-full hover:bg-red-500/80 transition-colors`}
                >
                  <FaHeart className="text-white text-sm" />
                </button>
                <button
                  onClick={() => handleShare(car)}
                  className="p-2 bg-gray-900/80 rounded-full hover:bg-blue-500/80 transition-colors"
                >
                  <FaShare className="text-white text-sm" />
                </button>
              </div>
            </div>
            <div className="p-6 space-y-4 flex flex-col justify-between h-[calc(100%-12rem)]">
              <div>
                <h3 className="text-xl font-bold text-white">{car.model}</h3>
                <p className="text-lg font-bold text-red-500">€{car.precio}</p>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center space-x-2">
                    <FaCalendarAlt className="text-red-400" />
                    <span className="text-gray-300">{car.year}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaTag className="text-red-400" />
                    <span className="text-gray-300">{car.color}</span>
                  </div>
                </div>
              </div>
              <div className="pt-4 border-t border-gray-700 mt-auto space-y-2">
                <button
                  onClick={() => handleSolicitarCotizacion(car)}
                  className="w-full bg-gradient-to-r from-red-600 to-red-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-red-500/30 transition-all"
                >
                  Solicitar Información
                </button>
                <button
                  onClick={() => handleViewDetails(car)}
                  className="w-full bg-gray-700 text-white py-2 rounded-xl hover:bg-red-600 transition-all"
                >
                  Ver Detalles
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Renderizar los controles de paginación */}
      {renderPagination()}

      {renderCarDetailModal()}

      <AnimatePresence>
        {showShareModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="bg-gray-800 rounded-2xl p-6 max-w-md w-full"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-white">Compartir Vehículo</h3>
                <button
                  onClick={() => setShowShareModal(false)}
                  className="p-2 hover:bg-gray-700 rounded-full"
                >
                  <FaTimes className="text-gray-400" />
                </button>
              </div>
              <div className="space-y-4">
                <input
                  type="text"
                  value={shareUrl}
                  readOnly
                  className="w-full bg-gray-700 rounded-lg px-4 py-2 text-white"
                />
                <button
                  onClick={copyToClipboard}
                  className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-blue-500/30 transition-all"
                >
                  Copiar Enlace
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Reemplazamos el botón de WhatsApp por el ChatbotButton */}
      <ChatbotButton />
    </div>
  );
};

export default SearchComponent;
