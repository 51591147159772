// src/components/ChatbotButton.js

import React, { useState, useEffect, useRef } from "react";
import {
  BsRobot,
  BsWhatsapp,
  BsMic,
  BsImage,
  BsTrash,
  BsPlayFill,
  BsPauseFill,
} from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoSend, IoFlashSharp } from "react-icons/io5";
import { supabase } from './supabaseClient'; // Asegúrate de que supabaseClient esté correctamente configurado

const ChatbotButton = () => {
  // Estados principales
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [previewMedia, setPreviewMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentCar, setCurrentCar] = useState(null);
  const [sentCars, setSentCars] = useState({});
  const [speaking, setSpeaking] = useState(false);
  const [currentSpeakingIndex, setCurrentSpeakingIndex] = useState(null);

  // Estados para grabación de audio
  const recognitionRef = useRef(null); // Ref para SpeechRecognition
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Ref para auto-scroll
  const messagesEndRef = useRef(null);

  // Nuevos estados para la animación de máquina de escribir
  const [isTyping, setIsTyping] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");
  const typingIntervalRef = useRef(null);

  // Estado para detectar si es móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Cargar mensajes desde localStorage al montar el componente
  useEffect(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  // Guardar mensajes en localStorage y hacer auto-scroll al actualizar mensajes
  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
    scrollToBottom();
  }, [messages]);

  // Efecto para hacer scroll cuando el mensaje de escritura cambia
  useEffect(() => {
    if (isTyping) {
      scrollToBottom();
    }
  }, [typingMessage]);

  // Función para hacer scroll al final del contenedor de mensajes
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Mensaje de saludo inicial
  const greetingMessage = "¡Hola! Soy Daniel, tu asesor personal de Accarvip. 🚗 ¿En qué tipo de vehículo estás interesado hoy?";

  // Función para abrir/cerrar el chatbot
  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    if (!isOpen && messages.length === 0) {
      setMessages([{ text: greetingMessage, sender: "bot" }]);
    }
    if (!isOpen) {
      // Hacer scroll al final después de abrir el chatbot
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };

  // Función para abrir WhatsApp
  const handleWhatsAppClick = () => {
    window.open("https://api.whatsapp.com/send?phone=34624081566", "_blank");
  };

  // Funciones para grabar audio usando Web Speech API
  const handleAudioRecord = () => {
    if (isRecording) {
      stopTranscription();
    } else {
      startTranscription();
    }
    setIsRecording(!isRecording);
  };

  const startTranscription = () => {
    // Verificar si el navegador soporta SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Tu navegador no soporta la transcripción de voz. Por favor, usa Google Chrome.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition;
    recognition.lang = "es-ES"; // Establecer el idioma a español de España
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.start();
    setIsTranscribing(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript.trim();
      // Reemplazar el contenido de inputMessage con el texto transcrito
      setInputMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Error en la transcripción de voz:", event.error);
      alert("Hubo un error al transcribir el audio. Por favor, intenta nuevamente.");
    };

    recognition.onend = () => {
      setIsTranscribing(false);
      setIsRecording(false);
    };
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  // Lista de modelos de coches conocidos
  const knownCarModels = ["Ferrari", "Lamborghini", "Porsche", "BMW", "Audi", "Mercedes", "Tesla", "Toyota", "Honda", "Ford"];

  // Función para verificar si el mensaje contiene un modelo de coche conocido
  const detectCarModel = (message) => {
    const regex = new RegExp(`\\b(${knownCarModels.join("|")})\\b`, "i");
    const match = message.match(regex);
    return match ? match[0] : null;
  };

  // Función para obtener información del coche desde Supabase
  const getCarInfo = async (carName) => {
    try {
      const { data, error } = await supabase
        .from("VEHÍCULOS")
        .select("*")
        .ilike("model", `%${carName}%`);

      if (error) {
        console.error("Error al obtener el coche de Supabase:", error);
        return null;
      }

      if (data && data.length > 0) {
        const alreadySent = sentCars[carName.toLowerCase()] || [];
        const availableCars = data.filter(car => !alreadySent.includes(car.id));

        if (availableCars.length === 0) {
          setSentCars(prev => ({
            ...prev,
            [carName.toLowerCase()]: []
          }));
          return getCarInfo(carName);
        }

        const randomIndex = Math.floor(Math.random() * availableCars.length);
        const selectedCar = availableCars[randomIndex];

        setSentCars(prev => ({
          ...prev,
          [carName.toLowerCase()]: [...(prev[carName.toLowerCase()] || []), selectedCar.id]
        }));

        return selectedCar;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error al obtener el coche de Supabase:", error);
      return null;
    }
  };

  // Función para obtener respuesta de la IA llamando a la función serverless
  const getAIResponse = async (userMessage, useSpanish = false, carInfo = null) => {
    try {
      const response = await fetch('/api/getAIResponse', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userMessage, useSpanish, carInfo, messages }),
      });

      const data = await response.json();

      if (response.ok && data.response) {
        return data.response;
      } else {
        console.error('Error en getAIResponse:', data.error);
        return useSpanish
          ? 'Lo siento, hubo un error al procesar tu solicitud.'
          : "I'm sorry, there was an error processing your request.";
      }
    } catch (error) {
      console.error('Error al llamar a getAIResponse:', error);
      return useSpanish
        ? 'Lo siento, hubo un error al procesar tu solicitud.'
        : "I'm sorry, there was an error processing your request.";
    }
  };

  // Función para reconocer el coche a partir de una imagen usando la función serverless
  const recognizeCarFromImage = async (imageFile) => {
    // Leer la imagen como base64
    const reader = new FileReader();
    const base64Image = await new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Remover el prefijo data:image/...;base64,
      reader.onerror = reject;
      reader.readAsDataURL(imageFile);
    });

    try {
      const response = await fetch('/api/recognizeCarImage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageBase64: base64Image }),
      });

      const data = await response.json();

      if (response.ok && data.carModel) {
        return data.carModel;
      } else {
        console.error('Error en recognizeCarFromImage:', data.error);
        return null;
      }
    } catch (error) {
      console.error('Error al llamar a recognizeCarFromImage:', error);
      return null;
    }
  };

  // Función para eliminar medios seleccionados
  const handleRemoveMedia = (index) => {
    setPreviewMedia(prev => prev.filter((_, i) => i !== index));
    setSelectedMedia(prev => prev.filter((_, i) => i !== index));
  };

  // Función para manejar la reproducción de texto a voz
  const handleTextToSpeech = (text, index) => {
    if (currentSpeakingIndex === index && speaking) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
      setCurrentSpeakingIndex(null);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "es-ES";
    utterance.onend = () => {
      setSpeaking(false);
      setCurrentSpeakingIndex(null);
    };

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
    setSpeaking(true);
    setCurrentSpeakingIndex(index);
  };

  // Función para enviar mensajes
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() || previewMedia.length > 0) {
      const text = inputMessage.trim();

      const newMessage = {
        text: text,
        media: [...previewMedia],
        sender: "user",
      };
      setMessages([...messages, newMessage]);
      setInputMessage("");
      setPreviewMedia([]);
      setSelectedMedia([]);

      // Procesar las imágenes enviadas
      if (previewMedia.length > 0) {
        setIsLoading(true);
        for (const media of previewMedia) {
          if (media.type === "image") {
            try {
              const carModel = await recognizeCarFromImage(media.originalFile);
              if (carModel) {
                const carInfo = await getCarInfo(carModel);
                if (carInfo) {
                  setCurrentCar(carInfo);
                  const carMessage = {
                    text: "",
                    sender: "bot",
                    carData: {
                      model: carInfo.model,
                      price: carInfo.precio,
                      year: carInfo.year,
                      color: carInfo.color,
                      image: carInfo.image,
                      link: carInfo.link,
                    }
                  };
                  setMessages(prev => [...prev, carMessage]);
                } else {
                  setMessages(prev => [...prev, { text: `No encontramos información para el coche "${carModel}".`, sender: "bot" }]);
                }
              } else {
                setMessages(prev => [...prev, { text: `No se pudo reconocer el modelo del coche en la imagen.`, sender: "bot" }]);
              }
            } catch (error) {
              setMessages(prev => [...prev, { text: `Hubo un error al reconocer el coche en la imagen.`, sender: "bot" }]);
            }
          }
        }
        setIsLoading(false);
      }

      // Procesar el texto enviado
      if (text) {
        setIsLoading(true);

        const detectedCar = detectCarModel(text);
        let carInfo = null;

        if (detectedCar) {
          carInfo = await getCarInfo(detectedCar);
          if (carInfo) {
            setCurrentCar(carInfo);
            const carMessage = {
              text: "",
              sender: "bot",
              carData: {
                model: carInfo.model,
                price: carInfo.precio,
                year: carInfo.year,
                color: carInfo.color,
                image: carInfo.image,
                link: carInfo.link,
              }
            };
            setMessages(prev => [...prev, carMessage]);
          } else {
            setMessages(prev => [...prev, { text: `No encontramos información para el coche "${detectedCar}".`, sender: "bot" }]);
          }
        }

        const useSpanish = !detectedCar;
        const aiResponseText = await getAIResponse(text, useSpanish, carInfo);

        if (aiResponseText) {
          // Iniciar la animación de máquina de escribir
          setIsTyping(true);
          setTypingMessage("");

          const botMessage = {
            text: "",
            sender: "bot",
          };
          setMessages(prev => [...prev, botMessage]);

          let currentIndex = 0;
          const typingSpeed = 50; // Milisegundos por carácter

          typingIntervalRef.current = setInterval(() => {
            if (currentIndex < aiResponseText.length) {
              setTypingMessage(prev => prev + aiResponseText[currentIndex]);
              currentIndex++;
            } else {
              clearInterval(typingIntervalRef.current);
              typingIntervalRef.current = null;
              setIsTyping(false);
              // Actualizar el último mensaje del bot con el texto completo
              setMessages(prev => {
                const updatedMessages = [...prev];
                updatedMessages[updatedMessages.length - 1].text = aiResponseText;
                return updatedMessages;
              });
            }
          }, typingSpeed);
        }

        setIsLoading(false);
      }
    }
  };

  // Función para detener la animación de escritura
  const stopTyping = () => {
    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
      typingIntervalRef.current = null;
      setIsTyping(false);
      // Actualizar el último mensaje del bot con el texto actual
      setMessages(prev => {
        const updatedMessages = [...prev];
        updatedMessages[updatedMessages.length - 1].text = typingMessage;
        return updatedMessages;
      });
    }
  };

  // Función para subir medios (imágenes)
  const handleMediaUpload = () => {
    if (selectedMedia.length >= 8) {
      alert("Máximo 8 archivos permitidos");
      return;
    }
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png"; // Limita a formatos compatibles
    input.multiple = true;
    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      const remainingSlots = 8 - selectedMedia.length;
      const filesToAdd = files.slice(0, remainingSlots);

      // Validar el tamaño y tipo de archivo
      const validFiles = filesToAdd.filter(file => {
        const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
        const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
        if (!isValidType) {
          alert(`El archivo ${file.name} no es un formato válido. Solo se permiten JPEG y PNG.`);
        }
        if (!isValidSize) {
          alert(`El archivo ${file.name} excede el tamaño máximo permitido de 5MB.`);
        }
        return isValidType && isValidSize;
      });

      setSelectedMedia([...selectedMedia, ...validFiles]);

      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileType = file.type.split("/")[0];
          setPreviewMedia(prev => [...prev, {
            type: fileType,
            url: event.target.result,
            name: file.name,
            size: (file.size / 1024).toFixed(2) + " KB",
            originalFile: file
          }]);
        };
        reader.readAsDataURL(file);
      });
    };
    input.click();
  };

  // Función para manejar el clic en una imagen y verla ampliada
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Función para renderizar la tarjeta del coche
  const renderCarCard = (carData) => (
    <div className="bg-black p-4 rounded-lg border border-red-500">
      <img src={carData.image} alt={carData.model} className="w-full rounded-lg" />
      <h3 className="text-white">{carData.model}</h3>
      <p className="text-gray-400">{carData.year} - {carData.color}</p>
      <p className="text-red-500">{carData.price} €</p>
      <div className="flex justify-between gap-2 mt-4">
        <a
          href={carData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-red-600 text-white p-2 rounded-lg w-full text-center">
          Ver Detalles
        </a>
      </div>
    </div>
  );

  // Función para renderizar cada mensaje
  const renderMessage = (message, index) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.text.split(urlRegex);
    const matches = message.text.match(urlRegex) || [];
    let currentUrlIndex = 0;

    return (
      <div className="space-y-2">
        <div className="flex items-start justify-between">
          <p className="text-sm md:text-base flex-grow">
            {parts.map((part, idx) => {
              if (idx === parts.length - 1) {
                return part;
              }
              const url = matches[currentUrlIndex++];
              return (
                <React.Fragment key={idx}>
                  {part}
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 underline break-all"
                  >
                    {url}
                  </a>
                </React.Fragment>
              );
            })}
          </p>
          {message.sender === "bot" && message.text && (
            <button
              onClick={() => handleTextToSpeech(message.text, index)}
              className={`ml-2 p-2.5 rounded-xl hover:scale-110 transform transition-all duration-300 ${
                currentSpeakingIndex === index && speaking
                  ? "bg-gradient-to-r from-red-500 to-pink-500 shadow-lg shadow-red-500/50"
                  : "bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg shadow-blue-500/50"
              }`}
            >
              {currentSpeakingIndex === index && speaking ? (
                <BsPauseFill className="w-4 h-4 text-white" />
              ) : (
                <BsPlayFill className="w-4 h-4 text-white" />
              )}
            </button>
          )}
        </div>
        {message.media && message.media.length > 0 && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            {message.media.map((media, mediaIndex) => (
              <div key={mediaIndex} className="relative">
                {media.type === "image" ? (
                  <div className="flex flex-col">
                    <img
                      src={media.url}
                      alt={media.name}
                      className="rounded-lg w-full h-24 object-cover cursor-pointer transition-transform hover:scale-105"
                      onClick={() => handleImageClick(media.url)}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="h-20 w-20 bg-gray-700 rounded-lg p-2 flex items-center justify-center">
                      <span className="text-xs text-center truncate">
                        {media.name}
                      </span>
                    </div>
                    <button
                      onClick={() => handleRemoveMedia(mediaIndex)}
                      className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                    >
                      <BsTrash size={12} className="mr-1" />
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Función para renderizar el mensaje de escritura
  const renderTypingMessage = () => (
    <div className="flex justify-start">
      <div className="bg-gray-900 text-gray-100 p-4 rounded-[20px]">
        <span className="text-sm">{typingMessage || "Daniel está preparando la mejor oferta..."}</span>
      </div>
    </div>
  );

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Botones de Chatbot y WhatsApp */}
      <div className="flex items-center relative gap-[25px]">
        {/* Botón de WhatsApp */}
        <div className={`absolute right-0 transform transition-all duration-300 ${isOpen ? "translate-x-[-75px] opacity-100" : "translate-x-0 opacity-0 pointer-events-none"}`}>
          <button onClick={handleWhatsAppClick} aria-label="WhatsApp" className="bg-emerald-500 text-white p-5 sm:p-4 rounded-2xl shadow-lg hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 transform hover:scale-105 hover:rotate-6">
            <BsWhatsapp className="w-8 h-8 sm:w-6 sm:h-6" />
          </button>
        </div>

        {/* Botón de Chatbot (oculto en móvil cuando está abierto) */}
        {!(isOpen && isMobile) && (
          <button onClick={toggleChatbot} aria-label="Abrir chatbot" className="bg-black text-white p-5 sm:p-4 rounded-2xl shadow-xl hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 transition-all duration-300 transform hover:scale-105 hover:-rotate-6 z-10 border border-red-500">
            {isOpen ? <IoMdClose className="w-8 h-8 sm:w-6 sm:h-6" /> : <BsRobot className="w-8 h-8 sm:w-6 sm:h-6" />}
          </button>
        )}
      </div>

      {/* Ventana del Chatbot */}
      {isOpen && (
        <div
          className={`fixed ${isMobile ? "top-0 left-0 w-full h-full" : "bottom-24 right-4 w-[350px] sm:w-[400px] h-[85vh] sm:h-[700px]"} bg-black ${isMobile ? "" : "rounded-[20px]"} shadow-2xl transform transition-transform duration-300 ease-in-out border border-red-500 overflow-hidden z-50`}
        >
          <div className="flex flex-col h-full backdrop-blur-lg bg-black/95">
            {/* Cabecera del Chatbot */}
            <div className={`bg-black text-white p-4 sm:p-6 ${isMobile ? "" : "rounded-t-[20px]"} flex items-center justify-between border-b border-gray-800 border-red-500`}>
              <div className="flex items-center space-x-3">
                <div className="bg-gray-800 p-2 rounded-xl rotate-12 hover:rotate-0 transition-all duration-300">
                  <BsRobot className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
                </div>
                <div>
                  <h2 className="text-lg sm:text-xl font-bold text-white">Accarvip - Asesor Virtual</h2>
                  <div className="bg-gradient-to-r from-red-500 to-purple-500 text-xs px-3 py-1 rounded-full text-white font-medium inline-block">
                    Accarvip + Uniclick
                  </div>
                </div>
              </div>
              <button onClick={toggleChatbot} aria-label="Cerrar chatbot" className="text-white hover:text-gray-300 focus:outline-none transform hover:rotate-90 transition-transform duration-300">
                <IoMdClose className="w-6 h-6 sm:w-7 sm:h-7" />
              </button>
            </div>

            {/* Contenedor de Mensajes */}
            <div className={`flex-1 overflow-y-auto p-4 sm:p-6 space-y-4 sm:space-y-6 bg-black scrollbar scrollbar-thumb-gray-700 scrollbar-track-gray-800 ${isMobile ? "" : "border-red-500 border"}`}>
              {messages.map((message, index) => (
                <div key={index} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                  <div className={`max-w-[90%] p-3 sm:p-4 rounded-[20px] transition-all duration-300 hover:scale-[1.02] ${message.sender === "user" ? "bg-gray-800 text-white" : "bg-gray-900 text-gray-100 border border-gray-800"}`}>
                    {message.carData ? renderCarCard(message.carData) : renderMessage(message, index)}
                  </div>
                </div>
              ))}
              {/* Mostrar el mensaje de escritura si la IA está escribiendo */}
              {isTyping && renderTypingMessage()}
              {/* Ref para auto-scroll */}
              <div ref={messagesEndRef} />
            </div>

            {/* Pie de Página con Campo de Entrada */}
            <div className={`flex flex-col bg-black ${isMobile ? "" : "border-red-500 border"}`}>
              {/* Vista Previa de Medios Seleccionados */}
              {previewMedia.length > 0 && (
                <div className="p-3 border-t border-gray-800">
                  <div className="flex gap-2 overflow-x-auto pb-2">
                    {previewMedia.map((media, index) => (
                      <div key={index} className="relative min-w-[100px] group">
                        {media.type === "image" ? (
                          <div className="flex flex-col">
                            <img
                              src={media.url}
                              alt={media.name}
                              className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                              onClick={() => handleImageClick(media.url)}
                            />
                            <button
                              onClick={() => handleRemoveMedia(index)}
                              className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                            >
                              <BsTrash size={12} className="mr-1" />
                              Eliminar
                            </button>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <div className="h-20 w-20 bg-gray-700 rounded-lg p-2 flex items-center justify-center">
                              <span className="text-xs text-center truncate">{media.name}</span>
                            </div>
                            <button
                              onClick={() => handleRemoveMedia(index)}
                              className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                            >
                              <BsTrash size={12} className="mr-1" />
                              Eliminar
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* Formulario de Envío de Mensajes */}
              <form onSubmit={handleSendMessage} className="p-3 sm:p-4 bg-black border-t border-gray-800">
                <div className="flex items-center bg-gray-900 rounded-[20px] p-2 shadow-inner hover:shadow-md transition-shadow duration-300">
                  {/* Botón para Grabar Audio */}
                  <button type="button" onClick={handleAudioRecord} className={`p-2 rounded-[20px] ${isRecording ? "text-red-500 bg-red-900/50" : "text-gray-300"} hover:bg-gray-800 focus:outline-none transition-all duration-300 hover:rotate-12`} aria-label="Grabar audio">
                    <BsMic className="w-4 h-4 sm:w-5 sm:h-5" />
                  </button>
                  {/* Botón para Subir Medios */}
                  <button type="button" onClick={handleMediaUpload} className="p-2 rounded-[20px] text-gray-300 hover:bg-gray-800 focus:outline-none transition-all duration-300 hover:-rotate-12" aria-label="Subir medios">
                    <BsImage className="w-4 h-4 sm:w-5 sm:h-5" />
                  </button>
                  {/* Campo de Entrada de Texto */}
                  <input 
                    type="text" 
                    value={inputMessage} 
                    onChange={(e) => setInputMessage(e.target.value)} 
                    placeholder="Pregúntame sobre nuestros vehículos..." 
                    className="flex-1 px-3 sm:px-4 py-2 bg-transparent focus:outline-none text-gray-100 placeholder-gray-400 transition-all duration-300 focus:placeholder-transparent text-sm sm:text-base" 
                    aria-label="Campo de mensaje" 
                  />
                  {/* Botón para Enviar o Cuadrito para Detener la Escritura */}
                  {isTyping ? (
                    <button 
                      type="button" 
                      onClick={stopTyping} 
                      aria-label="Detener escritura"
                      className="p-2 rounded-[20px] bg-gray-600 text-white hover:bg-gray-700 focus:outline-none transition-all duration-300"
                    >
                      {/* Puedes reemplazar esto con un icono de parada si lo prefieres */}
                      <div className="w-4 h-4 bg-white"></div>
                    </button>
                  ) : (
                    <button type="submit" aria-label="Enviar mensaje" className="p-2 rounded-[20px] text-gray-300 hover:bg-gray-800 focus:outline-none transition-all duration-300 hover:rotate-12" disabled={isLoading || isTranscribing}>
                      <IoSend className="w-4 h-4 sm:w-5 sm:h-5" />
                    </button>
                  )}
                </div>
              </form>
              {/* Pie de Página */}
              <div className="p-2 sm:p-3 bg-black rounded-b-[20px] flex items-center justify-center space-x-2 text-xs sm:text-sm text-gray-400 border-t border-gray-800">
                <IoFlashSharp className="w-3 h-3 sm:w-4 sm:h-4 text-yellow-500 animate-pulse" />
                <span className="font-medium text-gray-300">Powered by</span>
                <span className="text-gray-400">Uniclick.io</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Imagen Ampliada */}
      {selectedImage && (
        <div className="fixed inset-0 z-[60] bg-black bg-opacity-90 flex items-center justify-center p-4" onClick={() => setSelectedImage(null)}>
          <div className="relative max-w-4xl max-h-[90vh] w-full">
            <img
              src={selectedImage}
              alt="Vista ampliada"
              className="w-full h-full object-contain rounded-lg"
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
            >
              <IoMdClose className="w-6 h-6" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotButton;
